<template>
  <v-row :class="{ 'flex-column-reverse': $vuetify.breakpoint.xsOnly }">
    <v-col cols="12" md="6" class="pa-0 pr-0 pr-md-2">
      <v-btn
        :disabled="firstButtonDisabled"
        class="d-block mb-4 text-capitalize rounded-10 darken-3 w-100"
        style="height: 50px"
        color="primary"
        @click="$emit('firstButtonOnClick')"
      >
        <slot name="firstButton"></slot>
      </v-btn>
    </v-col>
    <v-col cols="12" md="6" class="pa-0 pl-0 pl-md-2">
      <v-btn
        :disabled="secondButtonDisabled"
        class="d-block ml-0 mb-4 text-capitalize rounded-10 w-100"
        style="height: 50px"
        color="#ffffff"
        @click="$emit('secondButtonOnClick')"
      >
        <slot name="secondButton"></slot>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NextBackButtons',
  props: {
    firstButtonDisabled: Boolean,
    secondButtonDisabled: Boolean
  },
  computed: {
    ...mapGetters('events', ['stepperStep'])
  },
  methods: {
    setStepperStep(direction) {
      // eslint-disable-next-line default-case
      switch (direction) {
        case '-':
          if (this.stepperStep > 1) {
            this.$store.commit('events/setStepperStep', this.stepperStep - 1)
          }
          break
        case '+':
          this.$store.commit('events/setStepperStep', this.stepperStep + 1)
          break
      }
      //   this.$store.commit('events/setStepperStep', step)
    }
  }
}
</script>
